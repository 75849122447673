"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("./common");
var types_1 = require("../../sources/types");
var icon = ((0, jsx_runtime_1.jsxs)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "-1.633235433328256 7.0326093303156565 131.26574682416876 114.63439066968435" }, { children: [(0, jsx_runtime_1.jsxs)("linearGradient", __assign({ id: "bgq", gradientUnits: "userSpaceOnUse", x1: "64", x2: "64", y1: "7.034", y2: "120.789" }, { children: [(0, jsx_runtime_1.jsx)("stop", { offset: "0", stopColor: "#4387fd" }), (0, jsx_runtime_1.jsx)("stop", { offset: "1", stopColor: "#4683ea" })] })), (0, jsx_runtime_1.jsx)("path", { d: "M27.79 115.217L1.54 69.749a11.499 11.499 0 0 1 0-11.499l26.25-45.467a11.5 11.5 0 0 1 9.96-5.75h52.5a11.5 11.5 0 0 1 9.959 5.75l26.25 45.467a11.499 11.499 0 0 1 0 11.5l-26.25 45.467a11.5 11.5 0 0 1-9.959 5.749h-52.5a11.499 11.499 0 0 1-9.96-5.75z", fill: "url(#bgq)" }), (0, jsx_runtime_1.jsx)("path", { clipPath: "url(#b)", d: "M119.229 86.48L80.625 47.874 64 43.425l-14.933 5.55L43.3 64l4.637 16.729 40.938 40.938 8.687-.386z", opacity: ".07" }), (0, jsx_runtime_1.jsxs)("g", __assign({ fill: "#fff" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M64 40.804c-12.81 0-23.195 10.385-23.195 23.196 0 12.81 10.385 23.195 23.195 23.195S87.194 76.81 87.194 64c0-12.811-10.385-23.196-23.194-23.196m0 40.795c-9.72 0-17.6-7.88-17.6-17.6S54.28 46.4 64 46.4 81.6 54.28 81.6 64 73.72 81.6 64 81.6" }), (0, jsx_runtime_1.jsx)("path", { d: "M52.99 63.104v7.21a12.794 12.794 0 0 0 4.38 4.475V63.104zM61.675 57.026v19.411c.745.137 1.507.22 2.29.22.714 0 1.41-.075 2.093-.189V57.026zM70.766 66.1v8.562a12.786 12.786 0 0 0 4.382-4.7v-3.861zM80.691 78.287l-2.403 2.405a1.088 1.088 0 0 0 0 1.537l9.115 9.112a1.088 1.088 0 0 0 1.537 0l2.403-2.402a1.092 1.092 0 0 0 0-1.536l-9.116-9.116a1.09 1.09 0 0 0-1.536 0" })] }))] })));
var bigQueryDestination = {
    description: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://cloud.google.com/bigquery" }, { children: "Google BigQuery" })), " ", "is a fast, scalable, and easy-to-use data warehouse. Main advantages of Google BiqQuery are:", (0, jsx_runtime_1.jsxs)("ul", { children: [(0, jsx_runtime_1.jsxs)("li", { children: [(0, jsx_runtime_1.jsx)("b", { children: "Serverless architecture" }), ".", " "] }), (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("b", { children: "Pay-as-you go" }) })] }), "Jitsu can", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://cloud.google.com/bigquery/streaming-data-into-bigquery" }, { children: "stream" })), " ", "and", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://cloud.google.com/bigquery/docs/batch-loading-data" }, { children: "batch" })), " ", "data to Google BigQuery. Streaming will get data to BQ immediately, however Google charges for each streamed record, while batching is free. Streaming is the fastest way to get started, but batching will be cheaper for large volumes."] })),
    syncFromSourcesStatus: "supported",
    id: "bigquery",
    type: "database",
    displayName: "BigQuery",
    ui: {
        icon: icon,
        connectCmd: function (cfg) {
            return "echo '".concat(cfg["_formData"]["bqJSONKey"].replaceAll("\n", " "), "' > bqkey.json;\\\ngcloud auth activate-service-account --key-file bqkey.json;\\\nbq query \"SELECT 1;\"");
        },
        title: function (cfg) {
            return cfg["_formData"]["pghost"];
        },
    },
    parameters: [
        {
            id: "$type",
            constant: "BQConfig",
        },
        (0, common_1.modeParameter)(),
        (0, common_1.tableName)(),
        {
            id: "_formData.bqProjectId",
            displayName: "Project Id",
            required: true,
            type: types_1.stringType,
        },
        {
            id: "_formData.bqDataset",
            displayName: "Dataset",
            defaultValue: "default",
            type: types_1.stringType,
        },
        {
            id: "_formData.bqJSONKey",
            displayName: "Access Key",
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Google Service Account JSON for BigQuery.", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://jitsu.com/docs/configuration/google-authorization#service-account-configuration" }, { children: "Read more about Google Authorization" }))] })),
            required: true,
            type: types_1.jsonType,
        },
        {
            id: "_formData.bqGCSBucket",
            documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Name of GCS Bucket. The bucket should be accessible with the same Access Key as dataset" }),
            displayName: "GCS Bucket",
            required: true,
            type: types_1.stringType,
            constant: (0, types_1.hiddenValue)("", function (cfg) {
                var _a;
                return ((_a = cfg === null || cfg === void 0 ? void 0 : cfg["_formData"]) === null || _a === void 0 ? void 0 : _a.mode) !== "batch";
            }),
        },
    ],
};
exports.default = bigQueryDestination;
