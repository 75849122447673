"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileParameters = exports.gcsCredentials = exports.s3Credentials = exports.tableName = exports.filteringExpressionDocumentation = exports.modeParameter = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../../sources/types");
var S3_REGIONS = [
    "us-east-2",
    "us-east-1",
    "us-west-1",
    "us-west-2",
    "ap-south-1",
    "ap-northeast-3",
    "ap-northeast-2",
    "ap-southeast-1",
    "ap-southeast-2",
    "ap-northeast-1",
    "ca-central-1",
    "cn-north-1",
    "cn-northwest-1",
    "eu-central-1",
    "eu-west-1",
    "eu-west-2",
    "eu-south-1",
    "eu-west-3",
    "eu-north-1",
    "me-south-1",
    "sa-east-1",
    "us-gov-east-1",
    "us-gov-west-1",
];
var modeParameter = function (constValue) {
    return {
        id: "_formData.mode",
        displayName: "Mode",
        documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "In steam mode the data will be send to destination instantly." }),
        required: true,
        defaultValue: constValue !== null && constValue !== void 0 ? constValue : "stream",
        constant: constValue !== null && constValue !== void 0 ? constValue : undefined,
        type: constValue ? types_1.stringType : (0, types_1.selectionType)(["stream", "batch"], 1),
    };
};
exports.modeParameter = modeParameter;
exports.filteringExpressionDocumentation = (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Table name (or table name template)." });
/**
 * Destination table name for DBS
 */
var tableName = function (customDocs) {
    return {
        id: "_formData.tableName",
        displayName: "Table Name",
        documentation: customDocs !== null && customDocs !== void 0 ? customDocs : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Table name (or table name template)." }),
        required: true,
        defaultValue: "events",
        type: types_1.stringType,
    };
};
exports.tableName = tableName;
function s3Credentials(regionField, bucketField, s3AccessKey, s3SecretKey, s3EndpointField, hide) {
    var params = [
        {
            id: regionField,
            displayName: "S3 Region",
            type: (0, types_1.selectionType)(S3_REGIONS, 1),
            required: true,
            defaultValue: "us-west-1",
            constant: (0, types_1.hiddenValue)("us-west-1", hide),
        },
        {
            id: bucketField,
            displayName: "S3 Bucket",
            type: types_1.stringType,
            required: true,
            constant: (0, types_1.hiddenValue)("", hide),
        },
        {
            id: s3AccessKey,
            displayName: "S3 Access Key",
            type: types_1.stringType,
            required: true,
            constant: (0, types_1.hiddenValue)("", hide),
        },
        {
            id: s3SecretKey,
            displayName: "S3 Secret Key",
            type: types_1.passwordType,
            required: true,
            constant: (0, types_1.hiddenValue)("", hide),
        },
    ];
    if (s3EndpointField) {
        params.push({
            id: s3EndpointField,
            displayName: "S3 Endpoint",
            documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Custom S3 endpoint. To use default Amazon endpoint leave this field empty." }),
            type: types_1.stringType,
            required: false,
            constant: (0, types_1.hiddenValue)("", hide),
        });
    }
    return params;
}
exports.s3Credentials = s3Credentials;
function gcsCredentials(accessKey, bucketField, hide, help) {
    return [
        {
            id: accessKey,
            displayName: "Access Key",
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Google Service Account JSON credentials for GCS Bucket.", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://jitsu.com/docs/configuration/google-authorization#service-account-configuration" }, { children: "Read more about Google Authorization" }))] })),
            required: true,
            type: types_1.jsonType,
            constant: (0, types_1.hiddenValue)("", hide),
        },
        {
            id: bucketField,
            documentation: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Name of GCS Bucket.", (help === null || help === void 0 ? void 0 : help.bucketField) ? " " + help.bucketField : ""] }),
            displayName: "GCS Bucket",
            required: true,
            type: types_1.stringType,
            constant: (0, types_1.hiddenValue)("", hide),
        },
    ];
}
exports.gcsCredentials = gcsCredentials;
function fileParameters(folderField, formatField, compressionField) {
    return [
        {
            id: folderField,
            displayName: "Folder",
            required: false,
            defaultValue: "",
            type: types_1.stringType,
        },
        {
            id: formatField,
            displayName: "Format",
            required: true,
            defaultValue: "json",
            type: (0, types_1.selectionType)(["json", "flat_json", "csv", "parquet"], 1),
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("b", { children: "json" }), " - not flattened json objects with \\n delimiter", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("b", { children: "flat_json" }), " - flattened json objects with \\n delimiter", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("b", { children: "csv" }), " - flattened csv objects with \\n delimiter", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("b", { children: "parquet" }), " - flattened objects which are stored as apache parquet file", (0, jsx_runtime_1.jsx)("br", {})] })),
        },
        {
            id: compressionField,
            displayName: "Enable gzip compression",
            required: false,
            type: types_1.booleanType,
            defaultValue: false,
            documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "If enabled - all files with events will be compressed (gzip) before uploading. All files will have the suffix '.gz'" })),
        },
    ];
}
exports.fileParameters = fileParameters;
