"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("./common");
var types_1 = require("../../sources/types");
var icon = ((0, jsx_runtime_1.jsxs)("svg", __assign({ enableBackground: "new 0 0 1615 1783.7", height: "100%", width: "100%", viewBox: "0 0 1615 1783.7", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "m807.5 1363.8 678.3 161.5v-1270.5l-678.3 161.5z", fill: "#205b97" }), (0, jsx_runtime_1.jsx)("path", { d: "m1485.8 254.8 129.2 64.6v1141.3l-129.2 64.6zm-678.3 1109-678.3 161.5v-1270.5l678.3 161.5z", fill: "#5193ce" }), (0, jsx_runtime_1.jsx)("path", { d: "m129.2 254.8-129.2 64.6v1141.3l129.2 64.6z", fill: "#205b97" }), (0, jsx_runtime_1.jsx)("path", { d: "m979.8 1783.7 258.4-129.2v-1525.3l-258.4-129.2-79 847z", fill: "#5193ce" }), (0, jsx_runtime_1.jsx)("path", { d: "m635.2 1783.7-258.4-129.2v-1525.3l258.4-129.2 79 847z", fill: "#205b97" }), (0, jsx_runtime_1.jsx)("path", { d: "m635.2 0h348.1v1780.1h-348.1z", fill: "#2e73b7" })] })));
var destination = {
    description: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "RedShift is a fast and scalable data warehouse provided by AWS. Jitsu can send data to RedShift in both stream, and batch mode. For batch mode you'll need to provide access to S3 bucket where Jitsu will store temporary data batches." })),
    syncFromSourcesStatus: "supported",
    id: "redshift",
    type: "database",
    displayName: "Redshift",
    ui: {
        title: function (cfg) { return cfg._formData.redshiftHost; },
        connectCmd: function (cfg) {
            return "PGPASSWORD=\"".concat(cfg["_formData"]["redshiftPassword"], "\" psql -U ").concat(cfg["_formData"]["redshiftUser"], " -d ").concat(cfg["_formData"]["redshiftDB"], " -h ").concat(cfg["_formData"]["redshiftHost"], " -p 5439 -c \"SELECT 1\"");
        },
        icon: icon,
    },
    parameters: __spreadArray(__spreadArray([
        (0, common_1.modeParameter)(),
        (0, common_1.tableName)(),
        {
            id: "_formData.redshiftHost",
            displayName: "Host",
            required: true,
            type: types_1.stringType,
        },
        {
            id: "_formData.redshiftDB",
            displayName: "Database",
            required: true,
            type: types_1.stringType,
        },
        {
            id: "_formData.redshiftSchema",
            displayName: "Schema",
            required: true,
            defaultValue: "public",
            type: types_1.stringType,
        },
        {
            id: "_formData.redshiftUser",
            displayName: "Username",
            required: true,
            type: types_1.stringType,
        },
        {
            id: "_formData.redshiftPassword",
            displayName: "Password",
            required: true,
            type: types_1.passwordType,
        },
        {
            id: "_formData.redshiftUseHostedS3",
            displayName: "Use Jitsu S3 bucket",
            type: types_1.booleanType,
            constant: false,
        }
    ], (0, common_1.s3Credentials)("_formData.redshiftS3Region", "_formData.redshiftS3Bucket", "_formData.redshiftS3AccessKey", "_formData.redshiftS3SecretKey", null, function (cfg) { var _a; return ((_a = cfg === null || cfg === void 0 ? void 0 : cfg._formData) === null || _a === void 0 ? void 0 : _a.mode) !== "batch"; }), true), [
        {
            id: "_users_recognition._enabled",
            displayName: "User Recognition",
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Jitsu can retroactively update events from anonymous users with user id after users identification. See", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://jitsu.com/docs/other-features/retroactive-user-recognition" }, { children: "Docs" })), "."] })),
            required: false,
            defaultValue: true,
            type: types_1.booleanType,
        },
    ], false),
};
exports.default = destination;
