"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("./common");
var icon = ((0, jsx_runtime_1.jsx)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("polygon", { fill: "#8C2F1E", points: "164.3,40.2 164.3,159 100,145.2 100,54.8  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#DF533F", points: "164.3,40.2 176.5,46.7 176.5,154.1 164.3,159  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#8D3322", points: "35.7,40.2 23.5,46.7 23.5,154.1 35.7,159  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#DF513D", points: "35.7,40.2 35.7,159 100,145.2 100,54.8  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#DE513D", points: "100,8.4 100,54.8 127.7,61.3 127.7,22.3  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#612015", points: "72.3,61.3 100,67 127.7,61.3 100,54.8  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#DE503C", points: "100,191.6 100,145.2 127.7,138.7 127.7,177.7  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#EFABA3", points: "127.7,138.7 100,133 72.3,138.7 100,145.2  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#DE503C", points: "127.7,80.9 127.7,119.9 100,123.2 100,76.8  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#8C301F", points: "100,8.4 100,54.8 72.3,61.3 72.3,22.3  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#8C301F", points: "100,191.6 100,145.2 72.3,138.7 72.3,177.7  " }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#8C301F", points: "72.3,80.9 72.3,119.9 100,123.2 100,76.8  " })] }) })));
var destination = {
    description: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "S3 Object Storage is ideal for backups and to archive company data. It is a convenient, affordable and compliant way to store any amount of static data" })),
    syncFromSourcesStatus: "not_supported",
    id: "s3",
    type: "other",
    displayName: "Amazon S3",
    ui: {
        icon: icon,
        title: function (cfg) {
            return cfg["_formData"]["s3Region"];
        },
        connectCmd: function (_) { return null; },
    },
    parameters: __spreadArray(__spreadArray([
        (0, common_1.tableName)(common_1.filteringExpressionDocumentation)
    ], (0, common_1.s3Credentials)("_formData.s3Region", "_formData.s3Bucket", "_formData.s3AccessKeyID", "_formData.s3SecretKey", "_formData.s3Endpoint", function (_) { return false; }), true), (0, common_1.fileParameters)("_formData.s3Folder", "_formData.s3Format", "_formData.s3CompressionEnabled"), true),
};
exports.default = destination;
