"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../../sources/types");
var logos = __importStar(require("../../sources/lib/logos"));
var icon = logos.plausible;
var plausibleDestination = {
    description: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Jitsu can send events from JS SDK or Events API to Plausible API filling as much Plausible Events Properties as possible from original event data." })),
    syncFromSourcesStatus: "not_supported",
    id: "plausible",
    type: "other",
    community: true,
    displayName: "Plausible",
    parameters: [
        {
            id: "_super_type",
            constant: "npm",
        },
        {
            id: "_package",
            constant: "@tangible/jitsu-plausible-destination@^1.1.0",
        },
        {
            id: "_formData.meta_package",
            displayName: "Community Package",
            type: types_1.descriptionType,
            defaultValue: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Implementation is based on ", (0, jsx_runtime_1.jsx)("b", { children: "npm-package:" }), " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://www.npmjs.com/package/@tangible/jitsu-plausible-destination" }, { children: "@tangible/jitsu-plausible-destination" })), " ", "(version: ^1.1.0)", (0, jsx_runtime_1.jsx)("br", {}), "Developed by", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://teamtangible.com" }, { children: "Tangible Inc" }))] })),
        },
        {
            id: "_formData.description",
            displayName: "Description",
            type: types_1.descriptionType,
            defaultValue: ((0, jsx_runtime_1.jsx)("span", { children: "Jitsu can send events from JS SDK or Events API to Plausible API filling as much Plausible Events Properties as possible from original event data." })),
        },
        {
            id: "_formData.plausible_domain",
            displayName: "Plausible Server base URL",
            required: true,
            type: types_1.stringType,
            defaultValue: "https://plausible.io",
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Plausible server URL including protocol, e.g.: ", (0, jsx_runtime_1.jsx)("code", { children: "https://plausible.io" })] })),
        },
        {
            id: "_formData.plausible_port",
            displayName: "Plausible server port",
            required: true,
            type: types_1.stringType,
            documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Plausible server port" }),
            defaultValue: "443",
        },
        {
            id: "_formData.anonymous",
            displayName: "Send anonymous data",
            required: false,
            type: types_1.booleanType,
            documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Send anonymous data to Plausible if true or all data including user data if false" }),
        },
    ],
    ui: {
        icon: icon,
        connectCmd: null,
        title: function (cfg) { return ""; },
    },
};
exports.default = plausibleDestination;
